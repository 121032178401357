<template>
    <div>
        <div class="text-center">
            <v-chip @click="setShow('times')" color="fav" text-color="white">
                <v-icon left color="primary">
                mdi-clock-time-four-outline
                </v-icon>
                זמני היום
            </v-chip>
            <v-chip class="mr-4" @click="setShow('shabat')" color="primary">
                <v-icon left color="fav">
                mdi-candle
                </v-icon>
                זמני השבת
            </v-chip>
        </div>

        <div class="text-center" v-if="show == 'shabat'">
           <ShabatTimes :shabat="shabat"/>     
        </div>

        <div class="text-center" v-if="show == 'times'">
            <iframe
                class="mt-4"
                src="https://www.yeshiva.org.il/calendar/YeshivaTimes.aspx?bgcolor=EFEFEF" 
                width="154" 
                height="270"
                frameborder="0" scrolling="no">
            </iframe>
        </div>
    </div>
</template>

<script>
import ShabatTimes from './ShabatTimes.vue';

export default {
    data() {
        return {
            showTimes: false,
            showShabat: false,
            show: '',
            shabat: {}
        }
    },
    methods: {
        setShow(value) {
            if(value == this.show) {
                this.show = ''
            }else {
                this.show = value
            }
        },
        initShabatObj() {
            let date = this.getShabatDay()
            fetch("https://data.gov.il/api/3/action/datastore_search?resource_id=cfe1dd76-a7f8-453a-aa42-88e5db30d567&limit=350")
            .then(response => response.json())
            .then(data => {
                let found = data.result.records.find(item => {
                    if(item.date == date) {
                        return item
                    }
                })
                this.shabat = Object.assign(this.shabat, found)
            });
        },
        getShabatDay() {
            let day = new Date()
            let dayOfWeek = 6
            day.setDate(day.getDate() + (dayOfWeek + 7 - day.getDay()) % 7);
            return day.toISOString().substring(0, 11) + '00:00:00'
        },
    },
    components: { 
        ShabatTimes 
    },
    created() {
        this.initShabatObj()
    }
}

</script>