<template>
    <div>
        <v-card
            class="pa-2 mt-4 mx-4"
            color="primary"
            elevation="4"
            dark>
            <v-container>
                <v-row>
                    <v-col cols="3">
                        <v-icon
                            large
                            color="fav">
                            mdi-candle
                        </v-icon>
                    </v-col>
                    <v-col cols="6">
                        <div class="text-center">
                            <h5>זמני השבת</h5>
                        </div>

                        <div class="mt-1 text-center">
                            <h3>{{ shabat.parasha }}</h3>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <v-icon
                            large
                            color="fav">
                            mdi-candle
                        </v-icon>
                    </v-col>
                </v-row>
                
            </v-container>
        
            <v-container>
                <v-row>
                    <v-col cols="4">

                    </v-col>
                    <v-col cols="4">
                        <h6 class="text-decoration-underline">כניסת השבת</h6>
                    </v-col>
                    <v-col cols="4">
                        <h6 class="text-decoration-underline">צאת השבת</h6>
                    </v-col>
                </v-row>
                
                <v-row class="mt-n4">
                    <v-col cols="4">
                        <h5>ירושלים</h5>
                    </v-col>
                    <v-col cols="4">
                        <h5>{{ shabat.Jerusalem_in.substring(0,5) }}</h5>
                    </v-col>
                    <v-col cols="4">
                        <h5>{{ shabat.Jerusalem_out.substring(0,5) }}</h5>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="4">
                        <h5>תל אביב</h5>
                    </v-col>
                    <v-col cols="4">
                        <h5>{{ shabat.TelAviv_in.substring(0,5) }}</h5>
                    </v-col>
                    <v-col cols="4">
                        <h5>{{ shabat.TelAviv_out.substring(0,5) }}</h5>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="4">
                        <h5>חיפה</h5>
                    </v-col>
                    <v-col cols="4">
                        <h5>{{ shabat.Hayfa_in.substring(0,5) }}</h5>
                    </v-col>
                    <v-col cols="4">
                        <h5>{{ shabat.Hayfa_out.substring(0,5) }}</h5>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="4">
                        <h5>באר שבע</h5>
                    </v-col>
                    <v-col cols="4">
                        <h5>{{ shabat.BeerSheva_in.substring(0,5) }}</h5>
                    </v-col>
                    <v-col cols="4">
                        <h5>{{ shabat.BeerSheva_out.substring(0,5) }}</h5>
                    </v-col>
                </v-row>
            </v-container>  
        </v-card>

         <JoinTelegram class="mt-4"/>
    </div>
</template>

<script>
import JoinTelegram from '@/components/JoinTelegram.vue'

export default {
    props:['shabat'],
    components: {
        JoinTelegram,
    }
}

</script>