<template>
    <div class="mx-auto" style="max-width:400px;">
       <v-card
            class="pa-3 mt-4"
            color="#212121"
            elevation="4"
            dark>

            <h5 class="text-center font-weight-medium">{{ joinTxt }}</h5>


            <v-btn
                class="mt-3 blue--text"
                color="white"
                @click="joinAmIsraeLiveTelegram"
                block>
                <v-avatar class="ml-3" size="22">
                    <img
                        src="@/assets/telegramicon.png"
                        alt="Telegram"/>
                </v-avatar>
                 להצטרפות לערוץ הטלגרם לחצו כאן
            </v-btn>

       </v-card>  
    </div>
</template>

<script>

export default {
    props: ['title'],
    data() {
        return {
            joinTxt: ''
        }
    },
    methods: {
        joinAmIsraeLiveTelegram() {
            window.open('https://t.me/amisraelive', '_blank')
        }
    },
    created() {
        if(this.title && this.title.length) {
            this.joinTxt = this.title
        } else {
            this.joinTxt = "הצטרפו לערוץ הטלגרם שלנו לקבלת עדכונים, תזכורות זמני השבת ועוד..."
        }
    }
}

</script>